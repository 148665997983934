@font-face {
  font-family: 'circular-pro-book';
  src: local('circular-pro-book'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-book.ttf) format('truetype');
}

@font-face {
  font-family: 'circular-pro-medium';
  src: local('circular-pro-medium'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-medium.ttf) format('truetype');
}

@font-face {
  font-family: 'circular-pro-bold';
  src: local('circular-pro-bold'), url(./fonts/circular-pro-cufonfonts/lineto-circular-pro-bold.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family: Helvetica; */
  color: #272727;
  font-family: circular-pro-book;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
